'use strict';

import { ServerConstants } from '../../core/serverconstants';
import { JobService } from '../../core/jobs/job.service';
import { ActivityService } from '../../core/dataservices/activity.service';
import { SelectedActivityFactory } from '../../core/selectedactivity.factory';
import { ProjectService } from '../../core/dataservices/project.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { ConstantsFactory } from '../../core/constants.factory';
import * as _ from 'lodash';
import { FeatureService } from '../../core/dataservices/feature.service';
import { SquareService } from '../../core/dataservices/square.service';
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { ClientService } from 'src/app/core/dataservices/client.service';
import { ProgramService } from 'src/app/core/dataservices/program.service';
import { ParentActivityChangeConfirm } from './../common/ParentActivityChangeConfirm';

export class ActivityInfoController extends ParentActivityChangeConfirm implements ng.IOnInit {
  static $inject = [
    'logger',
    '$stateParams',
    'activityservice',
    '$q',
    'selectedActivityFactory',
    'spinnerservice',
    '$mdDialog',
    '$state',
    'serverConstants',
    'projectservice',
    'jobservice',
    'constantsfactory',
    '$scope',
    '$location',
    'featureservice',
    'squareservice',
    'authService',
    'clientservice',
    'programservice',
  ];

  constructor(
    private logger: Logger,
    private $stateParams: ng.ui.IStateParamsService,
    private activityservice: ActivityService,
    private $q: ng.IQService,
    private selectedActivityFactory: SelectedActivityFactory,
    private spinnerservice: SpinnerService,
    protected $mdDialog: ng.material.IDialogService,
    private $state: ng.ui.IStateService,
    private serverConstants: ServerConstants,
    private projectservice: ProjectService,
    private jobService: JobService,
    private constantsfactory: ConstantsFactory,
    private $scope: ng.IScope,
    private $location: ng.ILocationService,
    private featureService: FeatureService,
    private squareService: SquareService,
    private authService: AuthService,
    private clientService: ClientService,
    private programService: ProgramService,
  ) {
    super($mdDialog);
  }

  private initial: {
    Type?: any;
    Format?: any;
    ActivityAccessSquareParticipants?: any;
  } = {};

  navigationErrorMessage =
    '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  languages = {};
  validationConstants = this.serverConstants.validationConstants;
  sequenceRoleConstants = this.serverConstants.activitySequenceRoleConstants;
  activity: any = {};
  isFirstEdit = true;
  activityInfoForm: ng.IFormController;
  isSaving = false;
  showComplexity = false;
  activityTypes = [];
  availableActivityTypes = [];
  squareJobIds: SquareJobId[] = [];
  nativeSquareJobsId: SquareJobId;
  decipherSquareJobId: SquareJobId;
  isInSitesUser = this.authService.isHuman8UserLogin();
  isInitializing = true;
  activityAccessOptions = [];
  isEditorRole = false;

  complexities = [
    {
      Complexity: 0,
      Stars: '- (no complexity)',
    },
    {
      Complexity: 1,
      Stars: '★',
    },
    {
      Complexity: 2,
      Stars: '★★',
    },
    {
      Complexity: 3,
      Stars: '★★★',
    },
    {
      Complexity: 4,
      Stars: '★★★★',
    },
    {
      Complexity: 5,
      Stars: '★★★★★',
    },
  ];
  dividedDiscussionTypesEnabled: boolean;
  scoutDiscussionTypesEnabled: boolean;
  isSavingUpRewardsEnabled: boolean = false;
  isActivityJobIdEverywhereEnabled: boolean = false;
  isDiy: boolean = false;
  hasNoDiyJobIdAvailable: boolean = false;
  shouldShowJobIdField: boolean = false;
  ActivityFormats = [
    {
      Label: 'Screener Activity',
      ActivityFormat: this.serverConstants.squareActivityFormatConstants
        .screener,
    },
    {
      Label: 'Profile Activity',
      ActivityFormat: this.serverConstants.squareActivityFormatConstants
        .profile,
    },
    {
      Label: 'Research Activity',
      ActivityFormat: this.serverConstants.squareActivityFormatConstants
        .research,
    },
  ];

  async $onInit() {
    this.logger.info('Activity details activated');
    this.spinnerservice.show('loading');
    this.$q.all([this.getActivityDetails() as any]).finally(async () => {
      this.logger.info('Activity details loaded');
      this.spinnerservice.hide('loading');
      const featureSettingsSavingUp = await this.featureService.getFeatureSettings(
        this.serverConstants.featureConstants.savingUpRewards,
      );

      this.isActivityJobIdEverywhereEnabled = await this.featureService.checkFeatureAccessibilityForSetup(
        this.serverConstants.featureConstants.activityJobIdEverywhere,
      );

      this.isSavingUpRewardsEnabled =
        featureSettingsSavingUp.isEnabled && featureSettingsSavingUp.isMasterSwitchEnabled;

      // If DIY Square, show self serve credit Jobs
      if (this.isDiy) {
        const credits = await this.programService.getOldestActiveProgramCreditsPerType(this.$stateParams.programGuid);
        const nativeSquareJobsIds = credits
          .filter((cred) => cred.Type === this.serverConstants.programCreditsTypeConstants.native)
          .map((cred) => ({ JobId: cred.JobId }));
        this.nativeSquareJobsId = nativeSquareJobsIds.length ? nativeSquareJobsIds[0] : null;

        const decipherSquareJobIds = credits
          .filter((cred) => cred.Type === this.serverConstants.programCreditsTypeConstants.decipher)
          .map((cred) => ({ JobId: cred.JobId }));
        this.decipherSquareJobId = decipherSquareJobIds.length ? decipherSquareJobIds[0] : null;
        this.setJobIdOptions();
        if (!this.activity.SquareJobId) {
          this.setDefaultDiyJobId();
        }
      } else if (this.isActivityJobIdEverywhereEnabled) {
        this.setJobIdOptions();
      } else if (this.isSavingUpRewardsEnabled) {
        // We don't need the all client jobids if Saving Up is enabled only the ones from the square
        const squareJobIds = await this.squareService.getSquareJobIds();
        this.squareJobIds = squareJobIds;
        if(!this.activity.SquareJobId) {
          this.activity.SquareJobId = squareJobIds[0];
        }
      }

      // If the activity already has a type, a format, it means that it was edited at least once (the fields are required)
      if (this.activity.Type > 0 && this.activity.Format > 0) {
        this.isFirstEdit = false;
      }

      if (this.activityTypes && this.activityTypes.length === 1) {
        this.activity.Type = this.activityTypes[0].ActivityType;
      }

      this.activityInfoForm.$setPristine();

      this.$scope.$watch('vm.activity.Type', (newVal, oldVal) => {
        if (!newVal || newVal === oldVal) {
          return;
        }

        this.setAvailableActivityTypes();

        // We don't want to overwrite the jobId during the initialization
        if (this.isDiy && !this.isInitializing) {
          this.setDefaultDiyJobId();
        }

        this.setShowComplexity();
        if (this.showComplexity) {
          this.setDefaultComplexity();
        }
      });

      this.$scope.$watch('vm.activity.Format', (newVal, oldVal) => {
        if (!newVal || newVal === oldVal) {
          return;
        }

        this.setAvailableActivityTypes();

        // If the currently selected ActivityType isn't in this.availableActivityTypes, set the default Activity Type based on the Activity Format
        if (
          _.indexOf(
            _.map(this.availableActivityTypes, (at) => at.ActivityType),
            this.activity.Type,
          ) === -1
        ) {
          this.setDefaultActivityType();
        }

        this.setShowComplexity();
        if (this.showComplexity) {
          this.setDefaultComplexity();
        }
      });

      this.shouldShowJobIdField = this.calculateShouldShowJobIdField();

      this.$scope.$watch('vm.activity.SquareJobId', (newVal, oldVal) => {
        if (!this.shouldShowJobIdField || !newVal || newVal === oldVal) {
          return;
        }
        // Remove serverErrors when changing to a different JobId
        this.activityInfoForm.SquareJobId.$setValidity('serverErrors', true);
      });
    });

    this.isInitializing = false;
  }

  setDefaultDiyJobId() {
    this.activity.SquareJobId = this.constantsfactory.isDecipherActivityType(this.activity.Type) ? this.decipherSquareJobId : this.nativeSquareJobsId;
    this.hasNoDiyJobIdAvailable = !this.activity.SquareJobId;
  }

  setJobIdOptions() {
    this.clientService.getAllClientJobIds().then((response) => {
      this.squareJobIds = response.List.map<SquareJobId>((job) => ({ JobId: job }));
    });
  }

  goBackAct() {
    if (this.$stateParams.referer) {
      this.$location.url(this.$stateParams.referer);
    } else if (
      this.activity.Type === this.serverConstants.squareActivityTypeConstants.confirmitSurvey ||
      this.activity.Type === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey ||
      this.activity.Type === this.serverConstants.squareActivityTypeConstants.decipherSurvey ||
      this.activity.Type === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey ||
      this.activity.Type === this.serverConstants.squareActivityTypeConstants.instinctSurvey
    ) {
      this.$state.go('root.square.activities.quant', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      });
    } else {
      this.$state.go('root.square.activities.qual', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      });
    }
  }

  async save() {
    await this.confirmParentActivityChanges();
    try {
      await this.updateActivity();
      this.invalidateCacheForInstinctSurveyActivity();
      this.logger.success('Activity info saved successfully');
    } catch {
      _.noop();
    }
  }

  invalidateCacheForInstinctSurveyActivity() {
    if(this.activity.Type === this.serverConstants.squareActivityTypeConstants.instinctSurvey) {
      this.activityservice.invalidateInstinctActivityCache();
    }
  }

  async saveAndContinue() {
    await this.confirmParentActivityChanges();
    await this.updateActivity();
    this.invalidateCacheForInstinctSurveyActivity();

    const state = 'root.square.activityWizardType';

    // Toasts get cleared on route but we want this one to remain visible
    // We add it to the route so the next component can read it and display it
    this.$state.go(state, {
      clientGuid: this.$stateParams.clientGuid,
      programGuid: this.$stateParams.programGuid,
      squareGuid: this.$stateParams.squareGuid,
      activityGuid: this.$stateParams.activityGuid,
      activityType: this.activity.Type,
      toastMessage: 'Activity successfully configured',
    });
  }

  validateNavigation() {
    const result = {
      canNavigate: true,
      isDirty: false,
    };

    result.isDirty = this.activityInfoForm.$dirty;
    return result;
  }

  setDefaultComplexity() {
    if (this.activity.Format === this.serverConstants.squareActivityFormatConstants.research) {
      switch (this.activity.Type) {
        case this.serverConstants.squareActivityTypeConstants.qualitativeResearch:
        case this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch:
        case this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch:
        case this.serverConstants.squareActivityTypeConstants.publicScoutResearch:
        case this.serverConstants.squareActivityTypeConstants.privateScoutResearch:
        case this.serverConstants.squareActivityTypeConstants.discussion20Research:
        case this.serverConstants.squareActivityTypeConstants.observation:
          this.activity.Complexity = 3;
          break;
        case this.serverConstants.squareActivityTypeConstants.checkMarketSurvey:
          this.activity.Complexity = 1;
          break;
        case this.serverConstants.squareActivityTypeConstants.confirmitSurvey:
        case this.serverConstants.squareActivityTypeConstants.decipherSurvey:
        case this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey:
        case this.serverConstants.squareActivityTypeConstants.instinctSurvey:
          this.activity.Complexity = 2;
          break;
      }
    }
  }

  setShowComplexity() {
    this.showComplexity = false;
    /* We are hiding right now the stars/complexity: PBI 37924
    this.showComplexity = this.activity.Format === this.serverConstants.squareActivityFormatConstants.research;
    */
  }

  setAvailableActivityTypes() {
    this.availableActivityTypes = _.filter(this.activityTypes, (at) => at.Format === this.activity.Format);
  }

  setDefaultActivityType() {
    switch (this.activity.Format) {
      case this.serverConstants.squareActivityFormatConstants.screener:
      case this.serverConstants.squareActivityFormatConstants.profile:
        this.activity.Type = this.serverConstants.squareActivityTypeConstants.decipherSurvey;
        break;
      case this.serverConstants.squareActivityFormatConstants.research:
        if (!this.dividedDiscussionTypesEnabled) {
          this.activity.Type = this.serverConstants.squareActivityTypeConstants.qualitativeResearch;
        } else {
          this.activity.Type = this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch;
        }
        break;
    }
  }

  isTypeLocked(): boolean {
    return this.availableActivityTypes.length === 1;
  }

  updateActivity() {
    this.isSaving = true;
    return this.activityservice
      .updateActivityInfo(this.activity)
      .then(
        () => {
          this.selectedActivityFactory.setActivityInfo({
            Guid: this.activity.Guid,
            Name: this.activity.Name,
            Format: this.activity.Format,
            HasMedia: this.activity.HasMedia,
          });

          this.isFirstEdit = false;
          this.initial.Type = this.activity.Type;
          this.initial.Format = this.activity.Format;

          if (this.activityInfoForm) {
            this.activityInfoForm.$setPristine();
          }
        },
        (error) => {
          if (error.status === 400) {
            const data = error.data;
            // Group by property name in case there is more than 1 error for that property
            // Ideally we should already group them in the backend
            const grouped = _.groupBy(data.ValidationErrors, 'PropertyName');
            _.forEach(grouped, (item, key) => {
              let message = '';
              _.forEach(item, (errorMessage) => {
                message += `${(errorMessage as any).ErrorMessage} `;
              });
              this.activityInfoForm[key].$setValidity('serverErrors', false);
              this.activityInfoForm[key].errorMessage = message;
            });
          }
        },
      )
      .finally(() => {
        this.isSaving = false;
      });
  }

  private getLanguageList() {
    return this.projectservice.getLanguageList().then((languages) => {
      this.languages = languages;
      return languages;
    });
  }

  private async getActivityDetails() {
    const [langs, resp] = await this.$q.all([
      this.getLanguageList(),
      this.activityservice.getActivityDetails(this.$stateParams.activityGuid),
    ]);

    const data = resp.data;
    this.dividedDiscussionTypesEnabled = data.DividedDiscussionTypesEnabled;
    this.scoutDiscussionTypesEnabled = data.ScoutDiscussionTypesEnabled;
    this.isDiy = data.CreditToClient;

    data.Language = data.Language
      ? _.find<any>(langs, (item) => {
        if (data.Language === item.Code) {
          return item.Name;
        }
      })
      : '';

    // Set list of available activity types
    this.logger.info('activityType set');

    // We keep the order from backend and ... is less to loop on
    for (const activityFormatString in this.serverConstants.squareActivityFormatConstants) {
      const activityFormat = this.serverConstants.squareActivityFormatConstants[activityFormatString];
      const activityTypeConstants = this.serverConstants.squareActivityTypeConstants;
      switch (activityFormat) {
        case this.serverConstants.squareActivityFormatConstants.profile:
        case this.serverConstants.squareActivityFormatConstants.screener: {
          const availableTypesForFormat = _.filter(resp.data.AvailableTypes,
            (at) => at === activityTypeConstants.checkMarketSurvey
              || at === activityTypeConstants.confirmitSurvey
              || at === activityTypeConstants.decipherSurvey
              || at === activityTypeConstants.instinctSurvey);
          for (const availableType in availableTypesForFormat) {
            this.addToAvailableTypes(availableTypesForFormat[availableType], activityFormat);
          }
          break;
        }
        case this.serverConstants.squareActivityFormatConstants.research:
          for (const activityType in resp.data.AvailableTypes) {
            this.addToAvailableTypes(resp.data.AvailableTypes[activityType], activityFormat);
          }
          break;
      }
    }
    data.ActivityAccessSquareParticipants = await this.loadAccessInformation();

    this.initial = data;
    if (data.SquareJobId) { // We store this as a string in the backend, but we get it as an object in the frontend
      data.SquareJobId = { JobId: data.SquareJobId };
    }
    this.activity = angular.copy(this.initial);

    this.setShowComplexity();
    this.setAvailableActivityTypes();

    return resp;
  }

  rollBackForm() {
    this.activity = angular.copy(this.initial);
    this.activityInfoForm.$setPristine();
    this.activityInfoForm.$setUntouched();
  }

  get showResetButton() {
    return (
      this.activity.IsPublished &&
      (this.activity.Type === this.serverConstants.squareActivityTypeConstants.confirmitSurvey ||
        this.activity.Type === this.serverConstants.squareActivityTypeConstants.decipherSurvey ||
        this.activity.Type === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey ||
        this.activity.Type === this.serverConstants.squareActivityTypeConstants.instinctSurvey) &&
      this.activity.Format !== this.serverConstants.squareActivityFormatConstants.screener
    );
  }

  async resetActivity() {
    await this.$mdDialog.show(
      this.$mdDialog.iscConfirm({
        title: 'Reset Activity',
        text: 'Are you sure you want to reset this activity?',
        ok: 'Yes',
        cancel: 'No',
      }),
    );

    const jobId = this.activityservice.resetActivity(
      this.$stateParams.activityGuid,
    );
    await this.jobService.showJobProgressDialog(
      jobId,
      `Resetting activity '${this.activity.Name}'`,
    );
  }

  squareJobIdsSearch(query) {
    return query
      ? _.filter(
        this.squareJobIds,
        (item: any) =>
          item.JobId.toLowerCase().indexOf(query.toLowerCase()) === 0,
      )
      : this.squareJobIds;
  }

  addToAvailableTypes(availableType: any, activityFormat: number) {
    const activityName = this.constantsfactory.GetLabelForSquareActivityType(
      availableType, activityFormat,
    );
    this.activityTypes.push({
      Label: activityName,
      ActivityType: availableType,
      Format: activityFormat,
    });
  }

  calculateShouldShowJobIdField() {
    // If the activity is DIY, we only show the JobId field for Human8 users and we ignore the other rules
    if (this.isDiy) {
      return this.isInSitesUser;
    }
    const featureTogglesEnabled = this.isActivityJobIdEverywhereEnabled || this.isSavingUpRewardsEnabled;
    return this.isInSitesUser && featureTogglesEnabled;
  }

  isJobIdRequired() {
    // If you don't show it, it is not required
    // If it is DIY, it is also not required
    return this.shouldShowJobIdField && !this.isDiy;
  }

  searchActivityAccess(query) {
    let results = [];
    if (query) {
      query = query.toUpperCase();
      const notSelectedAdmins = this.activityAccessOptions.filter((a) =>
        !this.activity.ActivityAccessSquareParticipants.map((s) => s.SquareParticipantGuid).includes(a.SquareParticipantGuid));
      results = notSelectedAdmins.filter((a) => a.Email.toUpperCase().includes(query));
    }
    return _.take(results, 100);
  }

  private async loadAccessInformation() {
    // Set the options
    this.activityAccessOptions = await this.activityservice
      .getAccessForActivity(this.$stateParams.activityGuid);
    // Return the already selected options
    return this.activityAccessOptions.filter((access) => access.HasSpecificAccess);
  }

  // Returns true when changes for the childActivities must be made
  protected hasChanges(): boolean {
    return this.initial.ActivityAccessSquareParticipants !== this.activity.ActivityAccessSquareParticipants;
  }

  private async confirmParentActivityChanges() {
    if (this.activity.ActivitySequenceRole === this.sequenceRoleConstants.parent) {
      await this.showParentActivityChangeConfirm();
    }
  }

}

interface SquareJobId {
  JobId: string
}
