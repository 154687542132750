'use strict';

import { IMemberItem, IActivityFilterData, IActivityReportData, IDecipherConfiguration, IReportDataTargetingHistory } from '../contracts/activity.contract';
import { ISurveyActivityResponse, IScreenerSurveysForOpenRegistrationResponse } from './dataservice.contracts';
import { HttpService } from './http.service';
import { IPromise } from 'angular';
import * as _ from 'lodash';
import { AuthService } from './auth.service';
import { Utils } from '../utils';
import { ServerConstants } from '../serverconstants';

export class ActivityService {
  static $inject = ['httpservice', 'authService', 'serverConstants'];

  _authService: AuthService;
  _serverConstants: ServerConstants;
  _moderationAndAnalysisDiscussionType: string = 'root.square.activitydata.conversations';

  constructor(
    private httpService: HttpService,
    authService: AuthService,
    serverConstants: ServerConstants,
  ) {
    this._authService = authService;
    this._serverConstants = serverConstants;
  }

  private invalidateCache() {
    this._getProgramActivities.cache = new _.memoize.Cache();
    this._getProfileActivities.cache = new _.memoize.Cache();
    this._hasInstinctActivityForSquare.cache = new _.memoize.Cache();
  }

  getModerationAndAnalysisDiscussionType() {
    return this._moderationAndAnalysisDiscussionType;
  }

  setModerationAndAnalysisDiscussionType(routeDefault: string) {
    this._moderationAndAnalysisDiscussionType = routeDefault;
  }


  getFilterDateRangeForActivity(activityType) {
    return this.httpService.get({
      url: '/ActivityService/GetFilterDateRangeForActivity',
      params: {
        activityType,
      },
    }).then((response) => response);
  }

  getActivityDetailsForMessage(activityGuid) {
    return this.httpService.get({
      url: `/ActivityService/GetActivityDetailsForMessage?Guid=${activityGuid}`,
    });
  }

  getSquareActivities(type, filter: IActivityFilterData, pageNumber: number, limit: number, order: string) {
    return this.httpService.get({
      url: '/ActivityService/ListActivitiesInSquare',
      params: {
        Type: type,
        ...filter,
        pageNumber,
        limit,
        order,
      },
    }).then((response) => response.data);
  }

  getProgramActivities(programGuid) {
    return this._getProgramActivities(programGuid);
  }

  private _getProgramActivities = _.memoize((programGuid) => this.httpService.get({
    url: `/ActivityService/ListActivitiesInProgram?programGuid=${programGuid}`,
  }).then((response) => response.data.List));

  listSurveyActivitiesForCurrentSquare(): IPromise<ISurveyActivityResponse[]> {
    return this.httpService.get({
      url: '/ActivityService/ListSurveyActivitiesForCurrentSquare',
    })
      .then((res) => _.map(res.data, (item) => ({
        activityGuid: item.ActivityGuid,
        activityName: item.ActivityName,
        surveyId: item.SurveyId,
        type: item.Type,
        displayLabel: item.DisplayLabel,
        surveyQuestions: item.SurveyQuestions,
      } as ISurveyActivityResponse)));
  }

  listScreenerSurveysForOpenRegistration(): IPromise<IScreenerSurveysForOpenRegistrationResponse[]> {
    return this.httpService.get({
      url: '/ActivityService/ListScreenerSurveysForOpenRegistration',
    })
      .then((res) => _.map(res.data, (item) => ({
        activityGuid: item.ActivityGuid,
        activityName: item.ActivityName,
        isOpenRegistrationScreener: item.IsOpenRegistrationScreener,
        actStatus: item.ActStatus,
      } as IScreenerSurveysForOpenRegistrationResponse)));
  }

  hasInstinctActivityForSquare(): IPromise<boolean> {
    return this._hasInstinctActivityForSquare();
  }

  invalidateInstinctActivityCache() {
    this._hasInstinctActivityForSquare.cache = new _.memoize.Cache();
  }

  private _hasInstinctActivityForSquare = _.memoize(() => this.httpService.get({
    url: '/ActivityService/HasInstinctActivityForSquare',
  }).then((response) => response.data as boolean));

  updateActivityInfo(activity) {
    this.invalidateCache();
    return this.httpService.post({
      url: '/ActivityService/UpdateActivityInfo',
      data: {
        Guid: activity.Guid,
        Name: activity.Name,
        Type: activity.Type,
        Format: activity.Format,
        Language: activity.Language.Code,
        Description: activity.Description,
        PIIsensitive: activity.PIISensitive,
        Complexity: activity.Complexity,
        SquareJobId: activity.SquareJobId ? activity.SquareJobId.JobId : null,
        ActivityAccessSquareParticipantGuids: activity.ActivityAccessSquareParticipants.map((sp) => sp.SquareParticipantGuid),
      },
    });
  }

  getProgramOrphans(programGuid) {
    return this.httpService.get({
      url: '/ActivityService/ListOrphan',
      params: {
        ProgramGuid: programGuid,
      },
    });
  }

  getActivityDetails(activityGuid, clientGuid: string = null) {
    const headers: any = {};
    if (clientGuid) {
      headers.ClientGuid = clientGuid;
    }
    return this.httpService.get({
      url: `/ActivityService/SelectActivity?Guid=${activityGuid}`,
      headers,
    });
  }

  selectActivityQuantResearch(guid) {
    return this.httpService.get({
      url: `/ActivityService/SelectActivityQuantResearch?Guid=${guid}`,
    });
  }

  async selectActivityQualResearch(guid) {
    const response = await this.httpService.get({
      url: '/ActivityService/SelectActivityQualResearch',
      params: { guid },
    });
    if (response.data.ConversationStimuli?.length) {
      response.data.ConversationStimuli.forEach((stimulus) => {
        stimulus.IsBrokenAndBelongsToCurrentUser = stimulus.IsBroken;
      });
    }
    return response;
  }

  async selectActivityQualResearchModerate(guid) {
    const response = await this.httpService.get({
      url: '/ActivityService/SelectActivityQualResearchModerate',
      params: { activityGuid: guid },
    });
    return response.data;
  }

  getActivitiesDropdown(includingChildActivityGuid: string = null) {
    return this.httpService.get({
      url: '/SquareService/ResearchActivitySelection',
      params: {
        includingChildActivityGuid,
      },
    }).then((response) => response.data.List);
  }

  getProfileActivities() {
    return this._getProfileActivities();
  }

  private _getProfileActivities = _.memoize(() => this.httpService.get({
    url: '/SquareService/ProfileActivitySelection',
  }).then((response) => response.data.List));

  getActivitySegmentations(activityGuid) {
    return this.httpService.get({
      url: `/ActivityService/ListActivitySegmentationSelection?Guid=${activityGuid}`,
    });
  }

  getActivityRecruitmentPartners(activityGuid) {
    return this.httpService.get({
      url: `/ActivityService/ListActivityRecruitmentPartners?ActivityGuid=${activityGuid}`,
    });
  }

  createActivity(activityName, programGuid, squareGuid, clonedActivityGuid) {
    this.invalidateCache();
    return this.httpService.post({
      url: '/ActivityService/CreateActivity',
      data: { name: activityName,
        programGuid, squareGuid,
        clonedActivityGuid,
        OnBehalfOfSquareParticipantGuid: this._authService.isImpersonating
          ? this._authService.impersonate.SquareParticipantGuid
          : null,
      },
    });
  }

  getAccessForActivity(activityGuid: string) {
    return this.httpService.get({
      url: '/ActivityService/GetAccessForActivity',
      params: {
        activityGuid,
      },
    }).then((response) => response.data);
  }

  getMissingSegmentations(activityGuid) {
    return this.httpService.post({
      url: '/ActivityService/GetMissingSegmentations',
      data: { activityGuid },
    }).then((response) => response.data);
  }

  getArchivedSegmentations(activityGuid) {
    return this.httpService.post({
      url: '/ActivityService/GetArchivedSegmentations',
      data: { activityGuid },
    }).then((response) => response.data);
  }

  updateActivityConfig(data) {
    this.invalidateCache();
    return this.httpService.post({
      url: '/ActivityService/UpdateActivityConfig',
      data,
    });
  }

  updateActivitySegmentations(activityGuid, segmentationGuids) {
    return this.httpService.post({
      url: '/ActivityService/UpdateActivitySegmentations',
      data: {
        ActivityGuid: activityGuid,
        SegmentationGuids: segmentationGuids,
      },
    });
  }

  createOrphan(orphanName, programGuid) {
    return this.httpService.post({
      url: '/ActivityService/CreateActivity',
      data: { name: orphanName, programGuid },
    });
  }

  publishActivity(data) {
    return this.httpService.post({
      url: '/ActivityService/publishActivity',
      data: {
        Guid: data,
      },
    });
  }

  getActivityRecruitmentPartner(activityRecruitmentPartnerGuid) {
    return this.httpService.get({
      url: `/ActivityService/SelectActivityRecruitmentPartnerDetail?RecruitmentPartnerGuid=${activityRecruitmentPartnerGuid}`,
    }).then((response) => response.data);
  }

  getActivityPartners(activityGuid, searchText) {
    return this.httpService.get({
      url: '/ActivityService/ListActivityPartners',
      params: {
        activityGuid,
        searchText,
      },
    });
  }

  getActivityDataReport(activityGuid): IPromise<IActivityReportData> {
    return this.httpService.get({
      url: '/ActivityService/ReportData',
      params: {
        activityGuid,
      },
    }).then((response) => {
      if (response.data) {
        _.forEach(response.data.TargetingHistory, (targetingHistory: IReportDataTargetingHistory) => {
          if (targetingHistory.TargetingQuota) {
            targetingHistory.TargetingQuota = Utils.convertPascalCasedObjectToCamelCasedObject(targetingHistory.TargetingQuota);
          }
        });
      }
      return response.data;
    });
  }

  checkActivityNameIsDuplicate(name) {
    return this.httpService.get({
      url: '/ActivityService/CheckActivityNameIsDuplicate',
      params: {
        name,
      },
    });
  }

  syncActivitiesQuestionnaire(activityGuids: string[]): IPromise<string> {
    return this.httpService.post({
      url: '/ActivityService/SyncActivitiesQuestionnaire',
      data: {
        activityGuids,
      },
    }).then((response) => response.data);
  }

  prepareSurvey(activityGuid) {
    return this.httpService.post({
      url: '/ActivityService/PrepareSurvey',
      params: {
        activityGuid,
      },
    }).then((response) => response.data);
  }

  toggleActivityPin(rootUrl, guid, clientGuid) {
    const headers: any = {};
    if (clientGuid) {
      headers.ClientGuid = clientGuid;
    }
    return this.httpService.post({
      url: '/ActivityService/ToggleActivityPin',
      data: { activityGuid: guid },
      headers,
    }, rootUrl).then((response) => response.data);
  }

  updateActivityQualSpecify(activityQual) {
    return this.httpService.post({
      url: '/ActivityService/UpdateActivityQualSpecify',
      data: {
        ActivityGuid: activityQual.ActivityGuid,
        Context: activityQual.Context,
        CompletionPoints: activityQual.CompletionPoints,
        Visibility: activityQual.Visibility,
        StartDate: activityQual.StartDate,
        EndDate: activityQual.EndDate,
        Offset: activityQual.Offset,
        CompletionTarget: activityQual.CompletionTarget,
        ThemeGuid: activityQual.ThemeGuid,
        CompleteCriterias: activityQual.CompleteCriterias,
        OnBehalfOfSquareParticipantGuid: this._authService.isImpersonating
          ? this._authService.impersonate.SquareParticipantGuid
          : null,
      },
    });
  }

  updateActivityQualSpecifyForDividedTypes(activityQual) {
    return this.httpService.post({
      url: '/ActivityService/UpdateActivityQualSpecifyForDividedTypes',
      data: {
        ActivityGuid: activityQual.ActivityGuid,
        CompletionPoints: activityQual.CompletionPoints,
        ModeratorCuration: activityQual.ModeratorCuration,
        Visibility: activityQual.Visibility,
        StartDate: activityQual.StartDate,
        EndDate: activityQual.EndDate,
        Offset: activityQual.Offset,
        CompletionTarget: activityQual.CompletionTarget,
        ThemeGuid: activityQual.ThemeGuid,
        OnBehalfOfSquareParticipantGuid: this._authService.isImpersonating
          ? this._authService.impersonate.SquareParticipantGuid
          : null,
        ContributionType: activityQual.ContributionType,
        VisibilityConditionType: activityQual.VisibilityConditionType,
        VisibilityConditionBufferHours: activityQual.VisibilityConditionBufferHours,
        VisibilityConditionDate: activityQual.VisibilityConditionDate,
      },
    });
  }

  updateActivityQualBuild(activityQual) {
    return this.httpService.post({
      url: '/ActivityService/UpdateActivityQualBuild',
      data: {
        ActivityGuid: activityQual.ActivityGuid,
        CommunicationGuid: activityQual.CommunicationGuid,
        ConversationGuid: activityQual.ConversationGuid,
        Title: activityQual.Title,
        Description: activityQual.Description,
        PrePopulateDescription: activityQual.PrePopulateDescription,
        IsOneByOne: activityQual.IsOneByOne,
        ConversationFocusType: activityQual.ConversationFocusType,
      },
    });
  }

  updateActivityQualPublish(activityQual) {
    return this.httpService.post({
      url: '/ActivityService/UpdateActivityQualPublish',
      data: {
        ActivityGuid: activityQual.ActivityGuid,
      },
    });
  }

  createUpdateActivityConfigCommunication(request, stimuli) {
    return this.httpService.upload({
      url: '/CommunicationService/CreateUpdateActivityConfigCommunication',
      data: {
        request: angular.toJson(request),
        files: stimuli,
      },
    }).then((response) => response.data);
  }

  createUpdateQualConversation(request, stimuli, attachments) {
    return this.httpService.upload({
      url: '/ConversationService/CreateUpdateQualConversation',
      data: {
        request: angular.toJson(request),
        files: stimuli,
        attachments,
      },
    }).then((response) => response.data);
  }

  getMemberList(activityGuid) {
    return this.httpService.get<{ MemberDetails: IMemberItem[] }>({
      url: '/ActivityService/GetMemberList',
      params: {
        activityGuid,
      },
    }).then((response) => response.data.MemberDetails);
  }

  resetActivity(activityGuid) {
    return this.httpService
      .post<string>({
        url: '/ActivityService/ResetActivity',
        data: { activityGuid },
      }).then((response) => response.data);
  }

  getActivityMaxStartDate(activityType) {
    return this.httpService.get({
      url: '/ActivityService/GetActivityMaxStartDate',
      params: {
        activityType,
      },
    }).then((response) => response.data);
  }

  updateLastSelectedDate(activityGuid: string) {
    return this.httpService.post({
      url: '/ActivityService/UpdateLastSelectedDate',
      params: {
        activityGuid,
      },
    });
  }

  deleteActivity(activityGuid: string) {
    return this.httpService.post({
      url: '/ActivityService/DeleteActivity',
      params: {
        activityGuid,
      },
    });
  }

  getActivityCountByType() {
    return this.httpService.get({
      url: '/ActivityService/GetActivityCountByType',
    }).then((response) => response.data);
  }

  toggleActivityArchive(activityGuid: string) {
    return this.httpService.post({
      url: '/ActivityService/ToggleActivityArchive',
      params: {
        activityGuid,
      },
    }).then((response) => response.data);
  }

  getIsDecipherProfileConfiguredCorrectly(activityGuid: string): IPromise<IDecipherConfiguration> {
    return this.httpService.get({
      url: '/ActivityService/GetIsDecipherProfileConfiguredCorrectly',
      params: {
        activityGuid,
      },
    }).then((response) => ({
      alwaysSaveDataConfigured: response.data.AlwaysSaveDataConfigured,
      delphiConfigured: response.data.DelphiConfigured,
      customJSConfigured: response.data.CustomJSConfigured,
      loadDataIsConfigured: response.data.LoadDataIsConfigured,
      surveyNodeFound: response.data.SurveyNodeFound,
      uniqueIdentifierInExtraVariables: response.data.UniqueIdentifierInExtraVariables,
      xmlFound: response.data.XmlFound,
    } as IDecipherConfiguration));
  }

  getIsDecipherSurveyPublished(activityGuid: string): IPromise<boolean> {
    return this.httpService.get({
      url: '/ActivityService/GetIsDecipherSurveyPublished',
      params: {
        activityGuid,
      },
    }).then((response) => response.data as boolean);
  }

  isQualResearchActivity(type: any): boolean {
    let result = false;
    switch (type) {
      case this._serverConstants.squareActivityTypeConstants.privateQualitativeResearch:
      case this._serverConstants.squareActivityTypeConstants.privateScoutResearch:
      case this._serverConstants.squareActivityTypeConstants.publicQualitativeResearch:
      case this._serverConstants.squareActivityTypeConstants.publicScoutResearch:
      case this._serverConstants.squareActivityTypeConstants.qualitativeResearch:
      case this._serverConstants.squareActivityTypeConstants.discussion20Research:
      case this._serverConstants.squareActivityTypeConstants.observation:
        result = true;
        break;
    }
    return result;
  }

  isQuantActivity(activityType: number) {
    return (
      activityType === this._serverConstants.squareActivityTypeConstants.confirmitSurvey ||
      activityType === this._serverConstants.squareActivityTypeConstants.checkMarketSurvey ||
      activityType === this._serverConstants.squareActivityTypeConstants.decipherSurvey ||
      activityType === this._serverConstants.squareActivityTypeConstants.decipherDiarySurvey ||
      activityType === this._serverConstants.squareActivityTypeConstants.instinctSurvey
    );
  }
}
