'use strict';

import { InstinctFeatureResponse } from '@api/models/query';
import { convertPascalCasedObjectToCamelCasedObject, useFeatureStore } from '@insites/vue-eco-temp-library';
import { IPromise } from 'angular';
import { memoize } from 'lodash';
import { HttpService } from './http.service';

export class FeatureService {
  static $inject = ['httpservice'];
  private masterFeaturesInvoked = false;

  constructor(private httpservice: HttpService) {
  }

  invalidateCache() {
    const store = useFeatureStore();
    store.$reset();
    this.masterFeaturesInvoked = false;
  }

  async getFeatureSettings(feature: number) {
    const store = useFeatureStore();
    const settings = store.getSettingsById(feature);
    if (settings) {
      return settings;
    }

    await store.fetchFeatureSettings(feature);
    return store.getSettingsById(feature);
  }

  // This should be called from configuration in order to see if the feature is MASTER enabled so that
  // Each square should or shouldn`t configure it
  // !!!! this just tells us if the feature is accessible for this square, it DOES NOT SAY that this feature is ENABLED on the square
  async checkFeatureAccessibilityForSetup(feature: number): Promise<boolean> {
    const store = useFeatureStore();
    const isAccessible = store.isFeatureAccessible(feature);
    if (isAccessible !== undefined) {
      return isAccessible;
    }

    await store.fetchFeatureAccessibility(feature);
    return store.isFeatureAccessible(feature);
  }

  // This should be called from feature and square to see if the feature is enabled on both MASTER and square
  // This normally should be called for the features themselves
  // !!!! this is the proper way of determining that the feature is enabled on both MASTER and SQUARE to be actually used
  async isFeatureEnabledForSquare(feature: number): Promise<boolean> {
    const store = useFeatureStore();
    const isEnabled = store.isFeatureEnabledForSquare(feature);
    if (isEnabled !== undefined) {
      return isEnabled;
    }

    await store.fetchFeatureEnabledForSquare(feature);
    return store.isFeatureEnabledForSquare(feature);
  }

  async isFeatureEnabledForParticipantInProgram(feature: number, programGuid: string) {
    const store = useFeatureStore();
    const isEnabled = store.isFeatureEnabledForParticipantInProgram(feature, programGuid);
    if (isEnabled !== undefined) {
      return isEnabled;
    }

    await store.fetchFeatureEnabledForParticipantInProgram(feature, programGuid);
    return store.isFeatureEnabledForParticipantInProgram(feature, programGuid);
  }

  invalidateFeatureSettings(feature: number) {
    const store = useFeatureStore();
    store.featureSettings.delete(feature);
    store.accessibleFeatures.delete(feature);
    store.enabledSquareFeatures.delete(feature);

    const keysToDelete: string[] = [];
    store.enabledProgramFeatures.forEach((_val, key) => {
      const parsedKey = JSON.parse(key);
      if (parsedKey.featureId === feature) {
        keysToDelete.push(key);
      }
    });
    keysToDelete.forEach((key) => store.enabledProgramFeatures.delete(key));
  }

  getMasterFeature = memoize(async (feature: number) => {
    const store = useFeatureStore();

    if (!this.masterFeaturesInvoked) {
      await store.fetchMasterFeatures();
      this.masterFeaturesInvoked = true;
    }

    return store.getMasterFeature(feature);
  });

  async isMasterFeatureEnabledForSquare(feature: number): Promise<boolean> {
    const store = useFeatureStore();
    const isEnabled = store.isMasterFeatureEnabledForSquare(feature);
    if (isEnabled !== undefined) {
      return isEnabled;
    }

    await store.fetchMasterFeatureEnabledForSquare(feature);
    return store.isMasterFeatureEnabledForSquare(feature);
  }

  getInstinctConfigurationForSquare(): IPromise<InstinctFeatureResponse> {
    return this.httpservice.get<InstinctFeatureResponse>({
      url: '/FeatureService/GetInstinctConfigurationForSquare',
    }).then((response) => convertPascalCasedObjectToCamelCasedObject(response.data));
  }
}
