import richInputFeatureConstructors from './features';
import { RichInputLengthCalculatorFeature } from './features/IRichInputFeature';
import { HotkeySubscription } from './features/HotKeySubscription';
import * as _ from 'lodash';
var IscRichInputController = (function () {
    function IscRichInputController($element, $document, $window, $injector, $scope) {
        this.$element = $element;
        this.$document = $document;
        this.$window = $window;
        this.$injector = $injector;
        this.$scope = $scope;
        this.focused = false;
        this.features = [];
        this.keyDownSubscriptions = [];
        this.keyUpSubscriptions = [];
        this.pasteSubscriptions = [];
        this.defaultNgButtons = 'emoji, bold, italic, underline, list1, list2, customlink, outdent, indent, removeFormat';
        this.autosaveTimeout = -1;
        this.autosaveUse = false;
        this.features = richInputFeatureConstructors.map(function (ctor) { return $injector.instantiate(ctor); });
    }
    IscRichInputController.prototype.$onInit = function () {
        var _this = this;
        if (this.buttons === 'none') {
            this.ngButtons = '';
            this.hideToolbar = true;
        }
        else {
            this.ngButtons = this.buttons || this.defaultNgButtons;
        }
        _.each(this.features, function (feature) {
            if (feature instanceof RichInputLengthCalculatorFeature) {
                _this.inputLengthCalculator = feature;
            }
            if (feature.init) {
                feature.init(_this);
            }
        });
        if (this.autosave) {
            this.setAutoSave();
        }
    };
    IscRichInputController.prototype.$postLink = function () {
        var _this = this;
        this.$document[0].execCommand('insertBrOnReturn', false);
        _.each(this.features, function (feature) {
            if (feature.postLink) {
                feature.postLink();
            }
        });
        var unwatch = this.$scope.$watch(function () { return _this.$element.find('ng-wig')[0].querySelector('#ng-wig-editable'); }, function (element) {
            if (element) {
                element.setAttribute('role', 'textbox');
                element.setAttribute('aria-multiline', 'true');
                unwatch();
            }
        });
    };
    IscRichInputController.prototype.$onDestroy = function () {
        _.each(this.features, function (feature) {
            if (feature.destroy) {
                feature.destroy();
            }
        });
    };
    IscRichInputController.prototype.subscribeToKeyDown = function (subscription) {
        this.keyDownSubscriptions.push(subscription);
    };
    IscRichInputController.prototype.subscribeToKeyUp = function (subscription) {
        this.keyUpSubscriptions.push(subscription);
    };
    IscRichInputController.prototype.subscribeToPaste = function (callback) {
        this.pasteSubscriptions.push(callback);
    };
    Object.defineProperty(IscRichInputController.prototype, "ngModelController", {
        get: function () {
            if (!this.modelController || this.isCustomPage) {
                var inputElem = this.$element.find('ng-wig');
                this.modelController = inputElem.controller('ngModel');
            }
            return this.modelController;
        },
        enumerable: false,
        configurable: true
    });
    IscRichInputController.prototype.getElement = function () {
        return this.$element;
    };
    IscRichInputController.prototype.getEditorContent = function () {
        var inputElem = this.$element.find('ng-wig');
        return inputElem[0].querySelector('#ng-wig-editable').textContent;
    };
    IscRichInputController.prototype.shouldShowCharacterCount = function () {
        var result = this.maxlength && this.maxlength > 0;
        if (result === true && this.characterCountEnabled !== null && this.characterCountEnabled !== undefined) {
            result = this.characterCountEnabled;
        }
        return result;
    };
    IscRichInputController.prototype.showValidInput = function () {
        return this.ngModelController && this.ngModelController.$valid && this.focused;
    };
    IscRichInputController.prototype.showMessages = function () {
        return this.ngModelController && (this.ngModelController.$dirty || this.ngModelController.$touched) && !_.isEmpty(this.ngModelController.$error);
    };
    IscRichInputController.prototype.isTouchedAndInvalid = function () {
        return this.ngModelController && (this.ngModelController.$dirty || this.ngModelController.$touched) && this.ngModelController.$invalid;
    };
    IscRichInputController.prototype.onTextPaste = function (e, pasteContent) {
        pasteContent = this.getPastedHtml(e, pasteContent);
        _.each(this.pasteSubscriptions, function (callback) {
            pasteContent = callback(pasteContent) || '';
        });
        this.gotFocus();
        return pasteContent;
    };
    IscRichInputController.prototype.getValueRealLength = function () {
        if (this.inputLengthCalculator) {
            return this.inputLengthCalculator.getValueRealLength();
        }
    };
    IscRichInputController.prototype.getPastedHtml = function (e, pasteContent) {
        var isText = true;
        if (this.$window.clipboardData && this.$window.clipboardData.getData) {
            pasteContent = this.$window.clipboardData.getData('Text');
            if (this.removeFormatting) {
                pasteContent = this.stripHtml(pasteContent);
            }
            isText = true;
        }
        else {
            var html = (e.originalEvent || e).clipboardData.getData('text/html');
            if (html) {
                isText = false;
                pasteContent = html
                    .replace(/<\/?[^>]+(>|$)/g, '')
                    .replace(/<a\b([^>]+)\bhref=/gi, '<a$1target="_blank" href=');
            }
            else {
                var text = (e.originalEvent || e).clipboardData.getData('text/plain');
                pasteContent = text;
                isText = true;
            }
            if (this.removeFormatting) {
                pasteContent = this.stripHtml(pasteContent);
            }
            if (isText) {
                var helperElement = document.createElement('div');
                helperElement.innerText = pasteContent;
                pasteContent = helperElement.innerHTML;
            }
        }
        return pasteContent;
    };
    IscRichInputController.prototype.stripHtml = function (text) {
        return text
            .replace(/<br.*?\/?>/gmi, '<br />')
            .replace(/<p.*?>/gmi, '<br />')
            .replace(/<a[^>]*href="(.*?)".*?>(.*?)<\/a>/gmi, ' $2 (Link->$1) ')
            .replace(/<!--[\s\S]*?-->/g, '')
            .replace(/<(?!\/?(b|strong|i|br|u|ul|ol|li|a|blockquote|img|span)\s*\/?>)[^>]*?>/gmi, '');
    };
    IscRichInputController.prototype.onKeyDown = function (event) {
        if (event.key === 'Tab') {
            return;
        }
        var theEvent = this.$window.event || event;
        this.forwardKeyboardEvent(theEvent, this.keyDownSubscriptions);
        if (this.onKeydown) {
            this.onKeydown();
        }
    };
    IscRichInputController.prototype.onKeyUp = function (event) {
        var _this = this;
        if (event.key === 'Tab') {
            return;
        }
        if (this.autosaveUse) {
            if (this.autosaveTimeout !== -1) {
                clearTimeout(this.autosaveTimeout);
            }
            this.autosaveTimeout = setTimeout(function () {
                if (_this.autosave) {
                    try {
                        _this.autosave();
                    }
                    catch (_a) {
                    }
                }
            }, 2000);
        }
        var theEvent = this.$window.event || event;
        this.forwardKeyboardEvent(theEvent, this.keyUpSubscriptions);
    };
    IscRichInputController.prototype.forwardKeyboardEvent = function (event, subscriptions) {
        _.each(subscriptions, function (sub) {
            if (sub instanceof HotkeySubscription) {
                sub = sub;
            }
            if (sub.match(event)) {
                sub.handler(event);
            }
        });
    };
    IscRichInputController.prototype.gotFocus = function () {
        this.focused = true;
        if (this.onFocus) {
            this.onFocus();
        }
    };
    IscRichInputController.prototype.loseFocus = function () {
        this.focused = false;
    };
    IscRichInputController.prototype.iscMentionSelectFn = function (item) {
        return this.iscMentionSelect({ item: item });
    };
    IscRichInputController.prototype.iscMentionSearchFn = function (term) {
        return this.iscMentionSearch({ term: term });
    };
    IscRichInputController.prototype.iscHashtagSelectFn = function (item) {
        if (this.iscHashtagSelect) {
            return this.iscHashtagSelect({ item: item });
        }
    };
    IscRichInputController.prototype.iscHashtagSearchFn = function (term) {
        if (this.iscHashtagSearch) {
            return this.iscHashtagSearch({ term: term });
        }
    };
    IscRichInputController.prototype.setAutoSave = function () {
        this.autosaveUse = true;
    };
    IscRichInputController.$inject = ['$element', '$document', '$window', '$injector', '$scope'];
    return IscRichInputController;
}());
export { IscRichInputController };
