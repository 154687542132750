'use strict';

import { SquareInstinctEnvironmentController } from './squareInstinctEnvironment.controller';
const template = require('./squareInstinctEnvironment.html');

require('./squareInstinctEnvironment.scss');

export const SquareInstinctEnvironmentComponent: ng.IComponentOptions = {
  controller: SquareInstinctEnvironmentController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    instinctEnvironmentType: '=',
    instinctEnvironmentId: '=',
    defaultInstinctEnvironment: '<',
    otherInstinctEnvironments: '<',
  },
  require: {
    form: '^^form',
  },
};
