'use strict';

import { ServerConstants } from '../../../core/serverconstants';
import { ActivityService } from '../../../core/dataservices/activity.service';
import * as _ from 'lodash';
import { InstinctEnvironmentSummary } from '@api/models/query';
import ng = require('angular');
import { AuthService } from '../../../core/dataservices/auth.service';
import { ITimeoutService } from 'angular';

export class SquareInstinctEnvironmentController implements ng.IOnInit {
  static $inject = ['serverConstants', 'activityservice', 'authService', '$timeout'];
  constructor(
    public serverConstants: ServerConstants,
    private activityService: ActivityService,
    private authService: AuthService,
    private $timeout: ITimeoutService,
  ) { }

  isInstinctEnvironmentEditable: boolean;
  searchInstinctEnvironment: string;
  instinctEnvironmentId: number;
  selectedInstinctEnvironment: InstinctEnvironmentSummary;
  defaultInstinctEnvironment: InstinctEnvironmentSummary;
  otherInstinctEnvironments: InstinctEnvironmentSummary[];
  instinctEnvironmentType: number;
  form: ng.IFormController;

  async $onInit() {
    // Check if an instinct activity was created if so disable all options. Only possible when an instinct environment has been configured
    let hasSquareInstinctActivities = false;
    if(this.instinctEnvironmentType !== this.serverConstants.squareInstinctEnvironmentTypeConstants.none) {
      hasSquareInstinctActivities = await this.activityService.hasInstinctActivityForSquare();
    }

    this.isInstinctEnvironmentEditable =
      !hasSquareInstinctActivities &&
      (this.authService.isModeratorUserLogin() ||
      this.authService.isHuman8UserLogin());

    if(this.instinctEnvironmentType === this.serverConstants.squareInstinctEnvironmentTypeConstants.otherEnvironment) {
      this.selectedInstinctEnvironment = this.otherInstinctEnvironments.find((env) => env.id === this.instinctEnvironmentId);
    }
  }

  // Gets called when an element in the dropdown is selected
  setEnvironmentId() {
    if(this.selectedInstinctEnvironment) {
      this.instinctEnvironmentId = this.selectedInstinctEnvironment.id;
    }
  }

  onEnvironmentTypeChanged() {
    this.$timeout(() => {
      if (this.instinctEnvironmentType === this.serverConstants.squareInstinctEnvironmentTypeConstants.defaultEnvironment) {
        this.instinctEnvironmentId = this.defaultInstinctEnvironment.id;
      } else if (this.instinctEnvironmentType === this.serverConstants.squareInstinctEnvironmentTypeConstants.otherEnvironment) {
        if (this.selectedInstinctEnvironment) {
          this.instinctEnvironmentId = this.selectedInstinctEnvironment.id;
        }
      }
    });
  }
  querySearchEnvironments(query) : InstinctEnvironmentSummary[] {
    return query ? this.createFilterForInstinctEnvs(query) : this.otherInstinctEnvironments;
  }

  createFilterForInstinctEnvs(query) : InstinctEnvironmentSummary[] {
    return _.filter(this.otherInstinctEnvironments, (item) => (item.name.toLowerCase().includes(query.toLowerCase())));
  }
}
