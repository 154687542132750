import * as _ from 'lodash';
import { IncentiveCardSampleRemovedReason, IncentiveCardType } from './incentive-card-type.enum';
import { DateTime } from 'luxon';
var IncentiveCardController = (function () {
    function IncentiveCardController(serverConstants, $state, iscClipboardService) {
        this.serverConstants = serverConstants;
        this.$state = $state;
        this.iscClipboardService = iscClipboardService;
        this.iconsList = [
            'fa fa-database',
            'fa fa-gift',
            'fa fa-calendar',
            'fa fa-gift',
            'fa fa-gift',
            'fa fa-database',
            'fa fa-database',
            '',
            'fa fa-gift',
            'fa fa-database',
            'fas fa-envelope',
            'fas fa-ban',
            'fas fa-exclamation',
        ];
        this.FIRST_IN_BATCH = 1;
        this.LAST_IN_BATCH = 2;
        this.NOT_IN_BATCH = 3;
        this.cardType = IncentiveCardType;
        this.cardSampleRemovedReason = IncentiveCardSampleRemovedReason;
    }
    IncentiveCardController.prototype.$onInit = function () {
        var _this = this;
        this.card.Type = this.findCardType(this.card);
        this.card.Icon = this.iconsList[this.card.Type];
        this.showRedeemButton =
            (this.card.Type === this.cardType.rewards || this.card.Type === this.cardType.manualRedemptionHistory)
                && this.card.IsRedeemable
                && this.card.Guid;
        this.dateTime = DateTime.fromISO(this.card.DateTime).toLocaleString(DateTime.DATETIME_MED);
        this.card.isOpen = this.card.Type === this.cardType.emptyContainer || this.card.Type === this.cardType.redemptionAction;
        if (this.card.Type === this.cardType.redemptionAction) {
            this.card.SavingUpUnits = _.sum(this.card.Children.map(function (row) {
                return (row.Type === _this.cardType.manualIntervention ?
                    row.RewardValue :
                    row.SavingUpUnits && row.IsSampleCompleted && row.Type === _this.cardType.rewardEarned
                        ? row.SavingUpUnits
                        : 0) + (row.Leftover || 0);
            }));
        }
        this.savingUpUnitLabel = this.card.SavingUpUnits === 1 ? this.unitLabel : this.unitsLabel;
    };
    IncentiveCardController.prototype.findCardType = function (card) {
        var type;
        if (card.Type) {
            type = card.Type;
        }
        else if (card.Credits) {
            type = this.cardType.credits;
        }
        else if (card.Currency) {
            type = this.cardType.rewards;
        }
        else {
            type = this.cardType.periodstart;
        }
        return type;
    };
    IncentiveCardController.prototype.isFirstInBatch = function (card) {
        return card.PositionInBatch === this.FIRST_IN_BATCH;
    };
    IncentiveCardController.prototype.isLastInBatch = function (card) {
        return card.PositionInBatch === this.LAST_IN_BATCH;
    };
    IncentiveCardController.prototype.isNotInBatch = function (card) {
        return card.PositionInBatch === this.NOT_IN_BATCH;
    };
    IncentiveCardController.prototype.getIncentiveLabel = function () {
        if (this.card.IncentiveLabel) {
            return "(".concat(this.card.IncentiveLabel, ")");
        }
        else {
            return '';
        }
    };
    IncentiveCardController.prototype.getInterventionLabel = function () {
        if (this.card.RewardValue < 0) {
            return '(SavingUpRewardRewardRemovedTitle)';
        }
        else {
            return '(SavingUpRewardRewardEarnedTitle)';
        }
    };
    IncentiveCardController.prototype.getRewardValue = function () {
        return this.card.RewardValue < 0
            ? this.card.RewardValue * -1
            : this.card.RewardValue;
    };
    IncentiveCardController.prototype.showStatusLabel = function () {
        return !!this.card.RewardWinnerStatusLabel;
    };
    IncentiveCardController.prototype.showPeriodInterval = function () {
        return this.card.isOutdated && !!this.card.PeriodStartDate && !!this.card.PeriodEndDate;
    };
    IncentiveCardController.prototype.getPointsNotEarnedLabel = function () {
        if (this.card.IsActivityFinished) {
            if (this.card.SampleStatus === this.serverConstants.sampleStatusConstants.inProgress) {
                return '(SavingUpRewardNotAllQuestionsHaveBeenAnsweredTitle)';
            }
            if (this.card.SampleStatus === this.serverConstants.sampleStatusConstants.toDo) {
                return '(SavingUpRewardRewardCouldHaveBeenEarnedTitle)';
            }
        }
        else {
            return '(SavingUpRewardYouDidNotTakePartToActivityTitle)';
        }
    };
    IncentiveCardController.prototype.goToLandingPage = function () {
        this.$state.go('container.main.incentive-landingpage-form', { rewardWinnerGuid: this.card.Guid });
    };
    IncentiveCardController.prototype.copyToClipboard = function (url) {
        this.iscClipboardService.copyToClipboard(url);
    };
    Object.defineProperty(IncentiveCardController.prototype, "isQualifiedForActivity", {
        get: function () {
            return this.card.IsSampleCompleted &&
                this.card.OutcomeCode !== this.serverConstants.surveyOutcomeCodeConstants.screened &&
                this.card.OutcomeCode !== this.serverConstants.surveyOutcomeCodeConstants.quotaFull;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IncentiveCardController.prototype, "isNotQualifiedForActivity", {
        get: function () {
            return this.card.IsSampleCompleted &&
                (this.card.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.screened ||
                    this.card.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.quotaFull);
        },
        enumerable: false,
        configurable: true
    });
    IncentiveCardController.$inject = ['serverConstants', '$state', 'iscClipboardService'];
    return IncentiveCardController;
}());
export { IncentiveCardController };
